.detail-dropdown-view {
    padding: 1em;
}

ul.trackable-item-actions {
    padding-left: 0;

    li {
        list-style: none;

        display: inline-block;
    }
}


.dropdown-item.highlighted {
    background-color: darkseagreen;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.datePicker {
    width: 100%;
}

.selected {
    font-weight: bold;
    color: #0b0b0b !important;
}


.navbar-list li .username { line-height: 16px; }
.navbar-list li .username p { color: var(--iq-body-text); font-size: 12px; }
